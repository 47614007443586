import(/* webpackMode: "eager" */ "/var/www/vhosts/gutonkenya.co.ke/guton/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/gutonkenya.co.ke/guton/components/footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/gutonkenya.co.ke/guton/components/header.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/gutonkenya.co.ke/guton/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/gutonkenya.co.ke/guton/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/gutonkenya.co.ke/guton/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
